import getAudioInputDevicesChangeHandler from './audioInputDevicesChangeHandler';
import getNativeMediaDevicesFactory from '../helpers/deviceHelpers';

const audioInputDevicesChangeHandler = getAudioInputDevicesChangeHandler();
const {
  getNativeMediaDevices,
} = getNativeMediaDevicesFactory();

export default (publisher) => {
  let isProcessing = false;
  const deviceChangeListener = async () => {
    if (isProcessing) {
      return;
    }
    isProcessing = true;
    const deviceId = await audioInputDevicesChangeHandler();
    isProcessing = false;
    publisher.setAudioSource(deviceId);
  };
  // In order to keep the current device ID synchronized, let's listen to the device change event
  const mediaDevices = getNativeMediaDevices();
  mediaDevices.addEventListener('devicechange', deviceChangeListener);

  const removeAudioInputDevicesChangeListener = () => {
    mediaDevices.removeEventListener('devicechange', deviceChangeListener);
  };

  return removeAudioInputDevicesChangeListener;
};
